import React, { useState, useEffect } from "react";
import Loader from "../../../components/loader";
import { useBalancerPoolsData } from "../../../context/balancerPoolData";
import { PoolType } from "../../poolDetail/balancer/types";
import Web3 from "web3";
import { useHistory } from "react-router-dom";

import { useGlobal } from "../../../context/globalContext";
import UiTable from "../../../components/table/Table";
import { useNetworkOptions } from "../../../context/contractDataLoaderService";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../../utils/helpers/networks";
import { ethers, providers } from "ethers";
import {
  contractAddress,
  SecondaryIssueManager,
  Security,
} from "@verified-network/verified-sdk";
import VerticallyModal from "../../../components/modal/VerticallyModal";
import { toast } from "react-toastify";
import { FailureToast, ToastOptions } from "../../../utils/constants";
import { Col, Row } from "react-bootstrap";
import UiButton from "../../../components/button";
import TextInput from "../../../components/textinput/TextInput";

function ServicerHome(props) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3, secondaryManagerContract;
  if (provider && provider.getSigner && chainId && address) {
    secondaryManagerContract = new SecondaryIssueManager(
      provider.getSigner(address),
      contractAddress[chainId].BalancerSecondaryIssueManager
    );
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }
  const { userRole, isUpdate, setIsUpdate } = useGlobal();
  const [tableData, setTableData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [modalView, setModalView] = useState(0);
  const [modalLoading, setModalLoading] = useState(false);
  const [changeContent, setChangeContent] = useState(false);
  const [rowData, setRowData] = useState({});
  const [issuingFee, setIssuingFee] = useState("");
  const [amountToBurn, setAmountToBurn] = useState("");
  const { data, loading } = useBalancerPoolsData(
    PoolType.SecondaryIssue,
    isUpdate
  );
  const { data: networkOptions } = useNetworkOptions();
  const history = useHistory();

  const fetchData = () => {
    let dataFetch;
    if (userRole == "DP" || userRole == "intermediary")
      dataFetch = data.filter(
        (pool) =>
          pool.verifiedWalletData?.issuer?.id.toLowerCase() ===
            account.toLowerCase() &&
          !pool.verifiedWalletData?.subscriptionsClosed.length
      );
    else if (userRole == "AM")
      dataFetch = data.filter(
        (pool) => !pool.verifiedWalletData?.subscriptionsClosed.length
      );
    setTableData(dataFetch);
  };

  useEffect(() => {
    fetchData();
  }, [data]);

  let headers = [
    { label: "Start Date", val: "createTime" },
    { label: "Security", val: "securitySymbolAsLink" },
    { label: "Currency", val: "currencySymbolAsLink" },
    { label: "Minimum Order Size", val: "minOrderSize" },
    { label: "Issue Fee", val: "issuingFee" },
    { label: "Call Action", val: "secondaryCallAction" },
  ];

  const options = {
    onClick: (event, row) => {
      onInvest(row);
    },
  };

  const onInvest = (row) => {
    if (userRole !== "AM")
      props.history.push(
        `/investors/secondary_investors/${row.poolType}/${row.security}`
      );
  };

  const commonProps = {
    showModal,
    updateShowModal: setShowModal,
    modalView,
    updateModalView: setModalView,
    updateRowData: setRowData,
  };

  const handleModalHide = () => {
    setShowModal(false);
    setModalView(0);
    setRowData({});
    setIssuingFee("");
    setAmountToBurn("");
    setChangeContent(false);
  };

  let heading, message;

  if (modalView == 1) {
    heading = "Set Issuing Fee";
    message = (
      <>
        {changeContent && (
          <UiButton
            onClick={() => {
              handleModalHide();
            }}
            buttonVariant="primary"
            buttonClass="SignUpButton flex-1 ml-0"
            buttonText="Click to close this form&nbsp;&rarr;"
            type="submit"
          />
        )}
        {!changeContent && (
          <div className="d-grid gap-2">
            <Row className="ml-1 align-items-center">
              <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                <Col xs={{ span: 3, offset: 2 }} className="text-left">
                  <b>Issuing Fee</b>
                </Col>
                <Col className="pl-0" xs={5}>
                  <TextInput
                    placeholder="Issuing Fee"
                    fieldType="number"
                    required
                    onChange={(e) => setIssuingFee(e.target.value)}
                  />
                </Col>
              </Row>

              <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                <Col className="pl-0">
                  <UiButton
                    onClick={async () => {
                      if (issuingFee === "") {
                        toast.error("Input Issuing Fee", ToastOptions);
                        return;
                      }
                      console.log(
                        "issuing fee clicked: ",
                        issuingFee,
                        ethers.utils
                          .parseUnits(issuingFee.toString(), 18)
                          .toString()
                      );
                      setModalLoading(true);
                      await secondaryManagerContract
                        .setIssuingFee(
                          rowData.security,
                          rowData.currency,
                          ethers.utils
                            .parseUnits(issuingFee.toString(), 18)
                            .toString()
                        )
                        .then(async (res) => {
                          if (res?.status === 0) {
                            setModalLoading(false);
                            toast.success(
                              "Transaction Successful",
                              ToastOptions
                            );
                            setChangeContent(true);
                          }
                          if (res?.status === 1) {
                            setModalLoading(false);
                            toast.error(FailureToast(), ToastOptions);
                          }
                        });
                    }}
                    buttonVariant="primary"
                    buttonClass="SignUpButton flex-1 ml-0"
                    buttonText="Set Fee"
                    type="submit"
                  />
                </Col>
              </Row>
            </Row>
          </div>
        )}
      </>
    );
  } else if (modalView == 2) {
    heading = "Burn Investor Security";
    message = (
      <>
        {changeContent && (
          <UiButton
            onClick={() => {
              handleModalHide();
            }}
            buttonVariant="primary"
            buttonClass="SignUpButton flex-1 ml-0"
            buttonText="Click to close this form&nbsp;&rarr;"
            type="submit"
          />
        )}
        {!changeContent && (
          <div className="d-grid gap-2">
            <Row className="ml-1 align-items-center justify-content-center">
              <b style={{ color: "#5062ca" }}>
                Investor is the first seller in the pool. Confirm address before
                burning security
              </b>
              <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                <Col xs={{ span: 3, offset: 2 }} className="text-left">
                  <b>Investor Address</b>
                </Col>
                <Col className="pl-0" xs={5}>
                  <TextInput
                    placeholder="Amount of security to burn"
                    disabled={true}
                    value={rowData.firstInvestorAddress}
                  />
                </Col>
              </Row>
              <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                <Col xs={{ span: 3, offset: 2 }} className="text-left">
                  <b>Amount To Burn</b>
                </Col>
                <Col className="pl-0" xs={5}>
                  <TextInput
                    placeholder="Amount of security to burn"
                    fieldType="number"
                    required
                    onChange={(e) => setAmountToBurn(e.target.value)}
                  />
                </Col>
              </Row>
              <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                <Col className="pl-0">
                  <UiButton
                    onClick={async () => {
                      console.log(
                        rowData.firstInvestorAddress,
                        rowData.securityDecimals
                      );
                      if (amountToBurn === "") {
                        toast.error("Input Amount To Burn", ToastOptions);
                        return;
                      }
                      if (
                        signer &&
                        rowData.firstInvestorAddress &&
                        rowData.securityDecimals &&
                        signer
                      ) {
                        const securityContract = new Security(
                          signer,
                          rowData.security
                        );
                        setModalLoading(true);

                        await securityContract
                          .burn(
                            rowData.firstInvestorAddress,
                            ethers.utils
                              .parseUnits(
                                amountToBurn.toString(),
                                Number(rowData.securityDecimals)
                              )
                              .toString()
                          )
                          .then(async (res) => {
                            if (res?.status === 0) {
                              setModalLoading(false);
                              toast.success(
                                "Transaction Successful",
                                ToastOptions
                              );
                              setChangeContent(true);
                            }
                            if (res?.status === 1) {
                              setModalLoading(false);
                              toast.error(FailureToast(), ToastOptions);
                            }
                          });
                      }
                    }}
                    buttonVariant="primary"
                    buttonClass="SignUpButton flex-1 ml-0"
                    buttonText="Burn Security"
                    type="submit"
                  />
                </Col>
              </Row>
            </Row>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {loading || modalLoading ? <Loader /> : null}
      <section className="d-flex flex-column align-items-start px-0 py-4">
        <div className="pools-table width-100">
          <UiTable
            thead={headers}
            tbodyData={tableData ?? []}
            hover
            bordered={false}
            userRole={userRole}
            rowEvents={options}
            web3={web3}
            chainId={chainId}
            isUpdate
            history={history}
            networkOptions={networkOptions}
            updateTableData={setIsUpdate}
            account={account}
            commonProps={commonProps}
            contract={secondaryManagerContract}
            signer={signer}
          />
        </div>
      </section>
      <VerticallyModal
        key="connectProvider"
        showModal={showModal}
        modalOnHide={handleModalHide}
        modalSize={"lg"}
        modalHeading={
          <h2>
            <b>{heading}</b>
          </h2>
        }
        withFooter={false}
      >
        {message}
      </VerticallyModal>
    </>
  );
}

export default function Secondary(props) {
  return <ServicerHome {...props} />;
}
